import { useState } from 'react';
import axios from 'axios';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { Box, Button, Container, FormControl, Grid, TextField, Typography } from '@mui/material';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const App = () => {
  const [data, setData] = useState();

  const [photo, setPhoto] = useState();

  const onChangeImage = (event) => {
    if (event.target.files[0]) {
      const reader = new FileReader();

      reader.addEventListener('load', () => {
        setPhoto((prevState) => ({
          ...prevState,
          pic: reader.result,
        }));
      });
      reader.readAsDataURL(event.target.files[0]);

      setPhoto((prevState) => ({
        ...prevState,
        image: event.target.files[0],
      }));
    }
  };

  const removePhoto = () =>
    setPhoto((prevState) => ({
      ...prevState,
      pic: '',
      image: null,
    }));

  const handleChange = (event) => {
    const key = event.target.name;
    const val = event.target.value;

    setData((prevState) => ({
      ...prevState,
      [key]: val,
    }));
  };

  const handleChangeDate = (date) => {
    setData((prevState) => ({
      ...prevState,
      due_date: moment(date).format('LL'),
    }));
  };

  const submit = async (event) => {
    event.preventDefault();

    const formData = new FormData();

    formData.append('image', photo?.image);

    Object.entries(data).map((el) => formData.append(el[0], el[1]));

    try {
      const response = await axios.post(
        `https://dev.toporin.com/wp-json/contact-form-7/v1/contact-forms/10/feedback`,
        formData,
      );

      if (response.status === 200) {
        event.target.reset();
        setData();
        setPhoto();
        toast.success('Your order sent');
      }
    } catch (error) {
      toast.error('Oooops! Something went wrong! Try again!');
      console.log(error);
    }
  };

  return (
    <Box component="section" sx={{ my: 4 }}>
      <Container maxWidth="sm">
        <Box className="image-wrapper" sx={{ textAlign: 'center' }}>
          <img src="../IMG_3412.jpg" alt="Fastprint inc" height="120px" />
        </Box>

        <Box className="header">
          <Button component="a" variant="text" startIcon={<LocalPhoneIcon />} href="tel:612-333-2255">
            612-333-2255
          </Button>

          <Button
            component="a"
            variant="text"
            startIcon={<LocationOnIcon />}
            href="https://goo.gl/maps/8Lxsbqqh8PwqGvjf8"
          >
            1218 Harmon Place, Minneapolis MN 55403
          </Button>
        </Box>

        <Box className="image-wrapper">
          <img src="../bg.jpg" alt="Fastprint inc" />
        </Box>

        <Grid container gap={2} className="content">
          <Grid item xs={12}>
            <Typography variant="h4" component="h2">
              Specialties
            </Typography>

            <Typography variant="body1">Fast service and quality products</Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="h4" component="h2">
              History
            </Typography>

            <strong>Established in 1965</strong>

            <Typography variant="body1">
              Fast Print was the 2nd "instant" printing company in Minnesota and has survived and thrived through the
              enormous changes in the industry for over 50 years!
            </Typography>
          </Grid>
        </Grid>

        <Box component="form" onSubmit={submit} sx={{ display: 'block' }}>
          <Typography variant="h5" sx={{ textAlign: 'center' }}>
            SEND A FILE
          </Typography>

          <TextField name="customer_name" label="Full Name" required fullWidth sx={{ mt: 2 }} onChange={handleChange} />

          <TextField
            name="customer_email"
            type="email"
            label="Email"
            required
            fullWidth
            sx={{ mt: 2 }}
            onChange={handleChange}
          />

          <TextField
            name="customer_phone"
            type="tel"
            label="Phone Number"
            required
            fullWidth
            sx={{ mt: 2 }}
            onChange={handleChange}
          />

          <TextField name="company" label="Company" fullWidth sx={{ mt: 2 }} onChange={handleChange} />

          <TextField name="job_title" label="Job Title" fullWidth sx={{ mt: 2 }} onChange={handleChange} />

          <TextField name="project_name" label="Project Name" fullWidth sx={{ mt: 2 }} onChange={handleChange} />

          <TextField
            name="message"
            label="Describe your project"
            multiline
            rows={4}
            fullWidth
            sx={{ mt: 2 }}
            onChange={handleChange}
          />

          <TextField
            name="quantity"
            type="number"
            label="Quantity"
            required
            fullWidth
            sx={{ mt: 2 }}
            onChange={handleChange}
          />

          <Box sx={{ mt: 2 }}>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker label="Due Date" sx={{ width: '100%' }} name="due_date" onChange={handleChangeDate} />
            </LocalizationProvider>
          </Box>

          {!photo?.pic && (
            <FormControl fullWidth sx={{ mt: 2 }}>
              <Button
                className="upload-btn"
                variant="text"
                component="label"
                sx={{ color: '#BDBDBD', border: '1px solid #bdbdbd', p: 4, fontWeight: 'bold', textAlign: 'center' }}
              >
                UPLOAD FILE
                <br />
                Valid Formats: jpeg / png / jpg / gif / pdf / psd / zip / ai / eps
                <input
                  hidden
                  // accept="image/*, .HEIC"
                  type="file"
                  name="photo"
                  multiple
                  onChange={onChangeImage}
                />
              </Button>
            </FormControl>
          )}

          {photo?.pic && (
            <Box className="photo-wrapper" fullWidth>
              Uploaded: {photo.image.name}
            </Box>
          )}

          {photo?.pic && (
            <Button variant="text" onClick={removePhoto} fullWidth sx={{ color: '#070707', mt: 2 }}>
              Remove File
            </Button>
          )}

          <Button variant="contained" type="submit" sx={{ my: 2, height: 45, float: 'right', display: 'block' }}>
            Send Project Request
          </Button>
        </Box>
      </Container>
      <ToastContainer />
    </Box>
  );
};

export default App;
